<template>
    <HeroPage v-if="pageData" :data="pageData" />
    <PageLayoutBuilder v-if="pageData" :data="pageData" />
</template>

<script>
import HeroPage from "@/components/HeroPage";

import PageLayoutBuilder from "@/components/PageLayoutBuilder";

import {
    fetchPageBySlug,
} from "@streampac.io/chef_sp_1";

export default {
    name: "VideoCatTechnicalSkills",
    inject: ["isAuthenticated"],
    // metaInfo() {
    //     return {
    //         title:
    //             "Home | LEAD Hockey",
    //         description:
    //             "",
    //     };
    // },
    data() {
        return {
            pageData: null,

        };
    },
    components: {
        HeroPage,
        PageLayoutBuilder,
    },
    watch: {
        isAuthenticated() {
            this.pageData = null;
            setTimeout(() => {
                this.fetchPage();
            }, 50);
        },
    },
    created() {
        this.fetchPage();
    },
    methods: {
        fetchPage() {
            fetchPageBySlug(this.isAuthenticated, this.$route.meta.slug).then(
                (response) => {
                    this.pageData = response;
                }
            );
        },
    },
};
</script>

<style lang="scss" scoped>
// @import styles
@import "./index.scss";
</style>
